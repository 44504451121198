.circular {
    display: inline-block;
    width: 62px;
    height: 62px;
    border-radius: 100%;
    overflow: hidden;
    background-color: $body-bg;

    &.small {
        width: 44px;
        height: 44px;
    }

    &.circular-bordered {
        border: 2px solid $brand-primary;
    }

    &.contain {
        img {
            max-height: 100%;
            width: auto;
        }
    }

    &.cover {
        img {
            margin: auto;
            vertical-align: middle;
        }
    }
}