.section-materials {
    .section-header {
        font-size: 24px;
        font-weight: 300;
        text-transform: uppercase;

        a.category {
            display: block;
            width: 100%;
            margin-bottom: 12px;
            text-decoration: none;
            color: $text-color-dark;

            &.active,
            &:hover {
                color: $brand-primary;
            }

            &.active {
                text-decoration: underline;
            }

            &:last-child {
                margin-bottom: 32px;
            }
        }

        .separator {
            display: none;
        }
    }

    .highlighted-block {
        display: block;
        transition: box-shadow 0.15s ease-in-out, transform 0.15s ease-in-out;

        &:hover {
            text-decoration: none;

            -webkit-transform: scale(1.01, 1.01);
            -moz-transform: scale(1.01, 1.01);
            -ms-transform: scale(1.01, 1.01);
            -o-transform: scale(1.01, 1.01);
            transform: scale(1.01, 1.01);
            -webkit-box-shadow: 0px 0px 10px 0 rgba(50, 50, 50, 0.3);
            -moz-box-shadow:    0px 0px 10px 0 rgba(50, 50, 50, 0.3);
            box-shadow:         0px 0px 10px 0 rgba(50, 50, 50, 0.3);
        }

        .body {
            color: $text-color;

            .title {
                font-size: 24px;
            }

            .read-more {
                color: $brand-primary;
                cursor: pointer;
            }
        }
    }
}

@media screen and (min-width: $screen-sm-min) {
    .section-materials {
        .section-header {
            a.category {
                display: inline;
                width: auto;
                margin-bottom: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
            
            .separator {
                display: inline;
            }
        }
    }
}