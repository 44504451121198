.sidebar-container {
    text-align: center;

    &.col-sm-4 {
        margin-top: $section-padding-normal;

        &:after {
            display: block;
            content: '';
            margin: auto;
            margin-top: $section-padding-normal;
            width: 86%;
            height: 1px;
            background-color: $text-color;

            .section-dark & {
                background-color: $text-color-dark;
            }
        }

        &.col-sm-pull-8 {
            &:after {
                display: none;
            }

            &:before {
                display: block;
                content: '';
                margin: auto;
                margin-bottom: $section-padding-normal;
                width: 86%;
                height: 1px;
                background-color: $text-color;
            }
        }
    }

    .container-title {
        font-size: 30px;
        line-height: 1.2em;
        font-weight: 300;
    }

    .nav > li > a {
        padding: 0 15px;
    }
}

@media screen and (min-width: $screen-sm-min) {
    .sidebar-container {
        text-align: left;

        &.col-sm-4 {
            margin-top: 0;

            &:after {
                display: none;
            }

            &:before {
                display: none;
            }

            &.col-sm-pull-8 {
                &:after {
                    display: none;
                }

                &:before {
                    display: none;
                }
            }
        }

        .container-title {
            text-align: left;
        }
    }
}