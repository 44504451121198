// Helper classes for text color
.text-color-primary {
    color: $brand-primary;
}

.text-color-dark {
    color: $text-color-dark;
}

.text-color-normal {
    color: $text-color;
}

.text-color-light {
    color: $text-color-light;
}

