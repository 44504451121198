.section-material-details {

    .swiper-container {
        width: 100%;
        height: 300px;
        margin-left: auto;
        margin-right: auto;
    }
    .swiper-slide {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .material-gallery {
        width: 100%;
        height: 336px;

        .swiper-slide {
            position: relative;

            .image {
                position: absolute;

                width: 100%;
                height: 100%;

                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }

            .fa {
                position: absolute;
                top: 20px;
                left: 20px;
                color: #999999;
                font-size: 20px;
                opacity: 0;
                transition: opacity 0.3s ease-in-out;
            }

            &:hover {
                .fa {
                    opacity: 1;
                }
            }
        }
    }

    .material-thumbs-gallery {
        width: 100%;
        height: 70px;
        box-sizing: border-box;
        padding: 10px 0;

        .swiper-slide {
            width: 55px !important;
            height: 50px;
            opacity: 0.4;

            &:hover {
                cursor: pointer;
            }
        }

        .swiper-slide-active {
            opacity: 1;
        }
    }

    .details-container {
        text-align: left;

        .title {
            font-size: 20px;
            font-weight: 300;
            color: $brand-primary;

            &:after {
                content: '';
                display: block;
                width: 100%;
                height: 2px;
                margin: 10px 0;
                background-color: #cccccc;
            }
        }

        .footer {
            text-align: right;

            .btn-primary {
                margin: 0;
            }

            .btn-back {
                position: relative;
                display: block;
                margin-top: 10px;
                font-size: 10px;
                font-weight: 300;
                color: #555;

                &:hover {
                    text-decoration: none;
                }

                &:before {
                    content: '>';
                    color: #555;
                }

                &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 50px;
                    height: 1px;
                    background-color: #555;
                    bottom: -2px;
                    right: 0;
                }
            }
        }
    }
}