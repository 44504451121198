.anchor-relative-to-nav {
    display: block;
    position: relative;
    top: -40px;
    visibility: hidden;
}

@media screen and (min-width: $screen-sm-min) {
    .anchor-relative-to-nav {
        top: -220px;
    }
}