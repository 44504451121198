.main-footer {
    position: relative;
    text-align: center;

    color: $text-color-dark;

    .footer-section-contact {
        .marker {
            margin-top: $content-margin-large;
            margin-bottom: $content-margin-normal;
        }

        .body {

        }
    }

    .footer-section-social {
        padding-top: $content-margin-large;
        padding-bottom: $content-margin-normal;
        padding-left: 22px;
        padding-right: 22px;
        margin-top: $content-margin-normal;
        background-color: $body-bg;
        text-align: center;

        .personal-contact-info {
            margin-top: $content-margin-normal;

            &:first-child {
                margin-top: 0;
            }
        }

        .social-links {
            margin-top: $content-margin-normal;

            .title {
                margin-top: 0;
                margin-bottom: $content-margin-small;
                text-transform: uppercase;
                color: $brand-primary;
                font-weight: 300;
                font-size: 18px;
            }

            .icon {
                margin-right: 6px;

                &:last-child {
                    margin-right: 0;
                }

                .fa-instagram {
                    position: relative;
                    top: -1px;
                }
            }
        }
    }

    .footer-section-footer {
        background-color: $body-bg-dark;
        padding-left: 14px;
        padding-right: 14px;

        line-height: 32px;
        vertical-align: middle;

        p {
            margin: 0;
        }

        .text-left,
        .text-right {
            text-align: center;
        }
    }
}

@media screen and (min-width: $screen-sm-min) {
    .main-footer {
        padding-top: $section-padding-large;
        background: $body-bg url('../images/footer-background.jpg') no-repeat center center;
        background-size: cover;

        $footer-map-height: 420px;

        &.main-footer-contact {
            min-height: $footer-map-height;
            background-image: none;
            background-color: #fff;


            #footer-map {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 10;
                height: $footer-map-height;
                width: 100%;
                background-color: #fff;
            }

            .positioning-container {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                max-width: 100%;
                height: 100%;
                z-index: 99;
                pointer-events: none;

                .footer-section-contact {
                    min-height: 247px;
                    pointer-events: none;
                }

                .footer-section-social,
                .footer-section-footer {
                    pointer-events: all;
                }
            }
        }

        .footer-section-contact {
            min-height: 180px;
        }

        .footer-section-social {
            text-align: right;

            .personal-contact-info {
                margin-top: 0;
            }

            .social-links {
                margin-top: 0;
            }
        }

        .footer-section-footer {
            .text-left {
                text-align: left;
            }

            .text-right {
                text-align: right;
            }
        }
    }
}