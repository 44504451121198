.icon {
    display: inline-block;
    width: 22px;
    height: 22px;
    background-color: $icon-inactive-color;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    border-radius: 11px;
    transition: background-color 0.33s;

    &:hover {
        background-color: $icon-active-color;

        .fa {
            color: $text-color-light;
        }
    }

    .fa {
        position: relative;
        vertical-align: middle;
        color: $text-color-light;
    }



    &.icon-large {
        width: 28px;
        height: 28px;
        border-radius: 14px;
        font-size: 19px;
        line-height: 28px;
    }
}


// Social Icon corrections
.icon {

    .fa-facebook {

    }

    .fa-twitter {
        top: -1px;
    }

    .fa-pinterest {
        left: 0.5px;
        top: -1.5px;
    }

    .fa-google-plus {
        right: -1px;
    }


    &.icon-large {
        .fa-facebook {

        }

        .fa-twitter {
            top: -1px;
            right: -1px;
        }

        .fa-pinterest {
            top: -1.5px;
        }

        .fa-google-plus {
            right: -2px;
        }
    }
}