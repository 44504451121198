.content-summary-line {
    padding: 0;

    .photo-container {
        padding: 0;
    }

    .details-container {
        //padding: 0;
        text-align: left;

        .title {
            margin-bottom: 0;
            font-size: 16px;
        }

        .summary {
            margin-top: 6px;
            margin-bottom: 0;
            padding-right: 15px;
            line-height: 12px;
            font-size: 12px;
            color: $text-color;
        }
    }

    &.content-summary-line-condensed {
        .photo-container {
            padding: 0;

            .circular {
                //width: 44px;
                //height: 44px;
            }
        }

        .details-container {
            position: relative;
            padding-left: 10px;
            height: 62px;

            .title {
                position: absolute;
                top: 50%;
                -webkit-transform: translate(0, -50%);
                -moz-transform: translate(0, -50%);
                -ms-transform: translate(0, -50%);
                -o-transform: translate(0, -50%);
                transform: translate(0, -50%);
                font-size: 14px;
            }
        }

        &.active,
        &:hover {
            .photo-container {
                .circular-bordered {
                    border-color: $text-color-dark;
                }
            }

            .details-container {
                .title {
                    color: $text-color-dark;
                    font-weight: normal;
                    font-size: 13px;
                }
            }
        }
    }
}