.main-header {
    margin-top: 0;
    background-color: $body-bg;

    border-bottom: 2px solid $brand-primary;
    text-align: center;

    .logo-container {
        .logo {
            max-width: 250px;
        }
    }

    .social-links {
        display: none;
    }
}

@media screen and (min-width: $screen-sm-min) {
    .main-header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;
        padding-top: 30px;
        padding-bottom: 30px;

        .logo-container {
            margin-bottom: 30px;
        }

        .social-links {
            display: block;
            position: absolute;
            top: $content-margin-normal;
            right: $content-margin-normal;

            .icon {
                margin-right: 3px;

                &:last-child {
                    margin-right: 0;
                }

                .fa-instagram {
                    position: relative;
                    top: -1px;
                }
            }
        }
    }

    body {
        padding-top: 180px;
    }
}