.navbar {
    font-weight: normal;

    &.navbar-default {
        margin-bottom: 0;

        .navbar-brand {
            max-width: 180px;
            padding-top: 8px;
            padding-bottom: 8px;
            line-height: 34px;

            .logo {
                max-width: 100%;
            }
        }

        .nav {
            li {
                a {
                    color: $text-color-dark;
                }

                &.active,
                &:hover {
                    a {
                        color: $brand-primary;
                    }
                }
            }

        }
    }
}

@media screen and (min-width: $screen-sm-min) {
    .navbar {
        &.navbar-default {
            min-height: inherit !important;
            text-align: center;

            .nav {
                display: inline-block;
                float: none;

                li {
                    &:first-child:before {
                        display: none;
                    }

                    &:before {
                        content: '';
                        float: left;
                        width: 1px;
                        height: 10px;
                        position: relative;
                        top: 6px;
                        display: inline-block;
                        border-left: 1px dotted $brand-primary;
                    }

                    a {
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                }
            }
        }
    }
}