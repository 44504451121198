.banner {
    display: none;
    padding: 16px 0 18px 0;
    background-color: $text-color-dark;
    color: $text-color-light;
    font-weight: 300;
    font-style: italic;
    font-size: 23px;
    line-height: 23px;

    .container {
        * {
            padding: 0;
            margin: 0;
        }
    }

    strong {
        color: $brand-primary;
        font-weight: 500;
        text-transform: uppercase;
    }
}

@media screen and (min-width: $screen-sm-min) {
    .banner {
        display: block;
    }
}