.contact-form {
    label {
        font-size: 12px;
        font-weight: normal;
    }

    input[type=submit] {
        min-width: 100%;
    }

    .form-success {
        overflow: hidden;
        max-height: 0;
        margin-bottom: 0;
        transition: max-height 0.4s ease-in-out, margin-bottom 0.4s ease-in-out;
    }

    &.has-success {
        .form-success {
            max-height: 200px;
            margin-bottom: 12px;
        }
    }
}

@media screen and (min-width: $screen-sm-min) {
    .contact-form {
        margin-top: $section-padding-normal;

        .form-group {
            &.col-sm-6:nth-child(2n+1) {
                padding-right: 10px;
            }

            &.col-sm-6:nth-child(2n+0) {
                padding-left: 10px;
            }
        }

        input[type=checkbox] {
            margin-right: 6px;
        }

        p {
            margin-bottom: 0;
        }

        label {
            font-size: 11px;
            line-height: 32px;
            font-weight: normal;
            margin-bottom: 0;
        }
    }
}