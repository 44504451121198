.summary-block-list {
    .content-summary-line {
        margin-top: $content-margin-smaller;
    }

    &.nav-stacked {
        li {
            &:hover {
                a {
                    background-color: transparent;
                }
            }
        }
    }
}