.personal-contact-info {
    .photo-container {
        text-align: center;
        padding: 0;

        img {
            height: 68px;
            width: auto;
        }
    }

    .contact-details-container {
        text-align: center;

        p {
            margin-top: $content-margin-small;
        }
    }
}

@media screen and (min-width: $screen-sm-min) {
    .personal-contact-info {
        .contact-details-container {
            display: table;
            padding-right: 0;
            min-height: 68px;
            vertical-align: middle;
            text-align: left;

            p {
                display: table-cell;
                margin-top: 0;
                vertical-align: middle;
            }
        }
    }
}