.section {
    padding-top: $section-padding-normal;
    padding-bottom: $section-padding-small;

    text-align: center;

    &.section-dark {
        background-color: $body-bg-dark;
        font-weight: normal;

        .title {
            &.inline-title {
                font-weight: normal;
            }
        }
    }

    &.section-medium {
        background-color: lighten($body-bg-dark, 2.5%);
        font-weight: normal;

        .title {
            &.inline-title {
                font-weight: normal;
            }
        }
    }

    .rounded-image {
        display: none;
    }

    &.hides-overflow {
        position: relative;
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .title {
        text-transform: uppercase;
        color: $brand-primary;
        font-weight: 300;
        margin-top: 0;
        margin-bottom: $content-margin-normal;

        &.slightly-smaller {
            font-size: 33px;
        }

        &.inline-title {
            font-size: $font-size-base;
        }

        strong {
            font-weight: 600;
            font-style: italic;
        }
    }

    .subtitle {
        margin-top: -0.5 * $content-margin-normal;
        margin-bottom: $content-margin-normal;
        color: $text-color-dark;
        font-weight: 300;

        &.subtitle-large {
            font-size: 16px;
            text-transform: uppercase;
        }
    }

    .footer {
        margin-top: $content-margin-large;

        .btn {
            width: 100%;
            margin-top: $content-margin-small;
        }
    }
}

@media screen and (min-width: $screen-sm-min) {
    .section {
        .footer {
            .btn {
                width: auto;
                margin-top: 0;
                margin-right: $content-margin-normal;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

@media screen and (min-width: $screen-md-min) {
    .section {
        &.has-rounded-image {
            min-height: 520px;

            .rounded-image {
                display: block;
                width: 476px;
                height: auto;
                position: absolute;
                right: 0;
                bottom: 0;
                z-index: 80;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .side-content-container {
                z-index: 90;
            }
        }
    }
}