.hero-header {
    .swiper-container {
        width: 100%;
        min-height: 56vh;
        max-height: 400px;

        .swiper-button-next,
        .swiper-button-prev {
            width: 27px;
            height: 49px;
            background: transparent url('../images/arrow-right.png') no-repeat center center;
        }

        .swiper-button-prev {
            background: transparent url('../images/arrow-left.png') no-repeat center center;
        }

        .swiper-slide {
            background-repeat: no-repeat;
            background-position-x: center;
            background-position-y: center;
            background-size: cover;

            &.background-center {
                background-position-y: center;
            }

            &.background-top {
                background-position-y: top;
            }

            &.background-bottom {
                background-position-y: bottom;
            }
        }
    }

    .title-container {
        display: table;
        height: 56vh;
        width: 100%;
        margin: 0 auto;
        padding: 0 14% 0 14%;

        &.constrained-to-container {
            padding: 0;
            max-width: $container-max-width;
        }

        &.position-left {
            text-align: left;
        }

        &.position-right {
            text-align: right;
        }

        .title {
            display: table-cell;
            vertical-align: middle;
            font-size: 44px;
            font-weight: 300;

            .small {
                display: block;
                margin-top: 20px;
                color: $brand-primary;
                font-weight: 300;
                font-size: 24px;
            }
        }
    }

    &.hero-header-small {
        .swiper-container {
            min-height: 15vh;
            max-height: 140px;
        }

        .title-container {
            height: 15vh;

        }
    }
}

@media screen and (min-width: $screen-sm-min) {
    .hero-header {

        .swiper-container {
            .swiper-button-next {
                right: 26px;
            }

            .swiper-button-prev {
                left: 26px;
            }
        }

        .title-container {
            padding: 0 10% 0 10%;

            .title {
                font-size: 70px;

                .small {
                    font-size: 32px;
                }
            }
        }
    }
}