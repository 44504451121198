$highlighted-block-top-offset: 76px;

.highlighted-block {
    max-width: 320px;
    margin: auto;

    &.dark {
        .header {
            background-color: $body-bg-darkest;

            .header-image {
                border-color: $body-bg-darkest;
            }

            .icon {
                background-color: $body-bg-darkest;
            }
        }
    }

    &.primary {
        .header {
            background-color: $body-bg-primary;

            .header-image {
                border-color: $body-bg-primary;
            }

            .icon {
                background-color: $body-bg-primary;
            }
        }
    }

    &.light {
        .header {
            background-color: $body-bg-darker;

            .header-image {
                border-color: $body-bg-darker;
            }

            .icon {
                background-color: $body-bg-darker;
            }
        }
    }

    &.with-icon {
        .body {
            padding-top: 100px;
        }
    }

    &.large {

    }

    .header {
        position: relative;
        padding: 6% 6% 44% 6%;

        .header-image {
            position: absolute;
            top: 10.5%;
            left: 6%;
            width: 88%;
            height: auto;
            max-height: 164px;
            background-color: $text-color-dark;
            border-bottom: 3px solid black;
        }

        .icon {
            position: absolute;
            width: 74px;
            height: 74px;
            left: 50%;
            bottom: -55.5%;
            margin-left: -37px;
            background-position: center center;
            background-repeat: no-repeat;

            &.icon-bedrijven {
                background-image: url('../images/icon-bedrijven.png');
            }

            &.icon-professionelen {
                background-position-y: 57%;
                background-image: url('../images/icon-professionelen.png');
            }

            &.icon-particulieren {
                background-image: url('../images/icon-particulieren.png');
            }
        }
    }

    .body {
        padding-top: 64px;

        padding-left: 15px;
        padding-right: 15px;
        background-color: $body-bg;

        .title {
            margin-bottom: 16px;
            font-size: 30px;
            color: $text-color-dark;
        }

        .footer {
            margin-top: 0;
            padding-bottom: 20px;
            font-weight: normal;
        }
    }
}

@media screen and (min-width: $screen-sm-min) {
    .highlighted-block-outer-container {
        padding-top: 0;
        margin-top: $highlighted-block-top-offset;
        padding-bottom: 0;
        margin-bottom: 0;

        &.has-section-header {
            margin-top: $highlighted-block-top-offset + 20px;
        }

        .section-header {
            margin-top: 46px;
            position: relative;
            top: -1.0 * $highlighted-block-top-offset - 20px;
        }

        .highlighted-block-inner-container {
            position: relative;
            top: -1.0 * $highlighted-block-top-offset;

            .highlighted-block {
                &.with-icon {
                    .body {
                        min-height: 266px;
                        max-height: 266px;
                    }
                }

                &.large {
                    .body {
                        min-height: 240px;
                        max-height: 240px;
                    }
                }

                .body {
                    min-height: 200px;
                    max-height: 200px;
                }
            }
        }

        .highlighted-block-container-additional-content {
            position: relative;
            top: -1.0 * $section-padding-normal / 2;
            padding-bottom: $section-padding-normal;
        }
    }
}

@media screen and (min-width: $screen-sm-min) and (max-width: $screen-md-min) {
    .highlighted-block-outer-container {
        .highlighted-block-inner-container {
            .highlighted-block {
                &:last-child {
                    margin-top: $content-margin-large;
                }
            }
        }
    }
}
