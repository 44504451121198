.side-content-container {
    margin-top: $section-padding-normal;

    &.col-sm-push-4 {
        margin-top: 0;
    }
}

@media screen and (min-width: $screen-sm-min) {
    .side-content-container {
        margin-top: 0;
        text-align: left;

        &.has-side-content-divider {
            position: relative;

            &:before {
                content: '';
                display: block;
                position: absolute;
                left: -15px;
                top: 10%;
                width: 1px;
                height: 80%;
                background-color: $text-color;

                .section-dark & {
                    background-color: $text-color-dark;
                }
            }
        }
    }
}