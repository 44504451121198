.has-content-divider {
    position: relative;
    padding-top: 16px;

    &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 99;
        top: -22.5px;
        left: 50%;
        margin-left: -22.5px;
        width: 45px;
        height: 45px;
        background-image: url('../images/content-divider.png');
        background-repeat: no-repeat;
        background-color: transparent;
    }
}