.modal {
    text-align: center;

    .modal-dialog {
        display: inline-block;
        text-align: left;
        vertical-align: middle;
        max-width: 480px;

        .modal-content {
            border-radius: 0;
            border: 1px solid $text-color-light;

            .modal-title {
                color: $text-color-dark;
                text-transform: uppercase;
                font-size: 24px;
            }

            .material-text {
                color: darken($text-color, 40%);
            }

            .modal-footer {
                text-align: center;
            }
        }
    }
}
@media screen and (min-width: 768px) {
    .modal:before {
        display: inline-block;
        vertical-align: middle;
        content: " ";
        height: 100%;
    }
}

